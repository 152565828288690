import icFav from '../images/icons/ic_fav.svg'
import icLink from '../images/icons/ic_link.svg'

function ShopProfilesHeader(props) {
    const { counter, profile, bloomName, isHidden } = props
 
    return (
		<div id={"profile-header" + counter} className={"profile-header-container " + (isHidden ? 'visually-hidden' : '')}>
			<div className="profile-image-container">
				<img className="profile-image" src={profile.photoUrl} alt="BloomTap Profile Image"/>
				<div>
					<h2>{profile.displayName}</h2> <a href={"https://open.bloomtap.io/bookmark?bloomname=" + bloomName}><img className="ic-fav" src={icFav}/></a>
					<div className="bloom-bloomname"><img src={icLink} />  {bloomName}</div>
				</div>
			</div>
		</div>
    )
}

export default ShopProfilesHeader
