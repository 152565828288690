import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
//import { useUserAuth } from "../context/UserAuthContext";
import Dropdown from 'react-bootstrap/Dropdown';
import imgBannerMobile from '../images/banner_m.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

import { addBloomViewCountByBloomName, findBloomByBloomName, findAllSocialLinksByBloomName, findAllBloomLinksByBloomName, findAllBloomFeatureImagesByBloomName } from '../services/bloom.mjs'
import { findAllLinkedProfilesByCompanyId } from '../services/profile.mjs'

import ShopProfilesHeader from '../components/profiles-header.js'
import ShopProfiles from '../components/profiles.js'
import BloomLinks from '../components/bloom-links.js'
import BloomFeatureImages from '../components/bloom-feature-images.js'

const onSelect = (eventKey, event) => {
	const el1 = document.querySelectorAll('.profile-container');
	const el2 = document.querySelectorAll('.profile-header-container');
	for (var i = 0; i < el1.length; ++i) {
	   el1[i].classList.add("visually-hidden");
	   el2[i].classList.add("visually-hidden");
	}
	
	const el3 = document.querySelector('#profile' + eventKey);
	const el4 = document.querySelector('#profile-header' + eventKey);
	el3.classList.remove("visually-hidden");
	el4.classList.remove("visually-hidden");
}

function Home() {
    const {paramBloomName} = useParams();
	//const { user } = useUserAuth();
	
    const [loading, setLoading] = useState(false)
    const [ipAddressView, setIPAddressView] = useState([])
    const [bloomName, setBloomName] = useState('')
    const [bloom, setBloom] = useState('')
    const [bloomBio, setBloomBio] = useState('')
    const [socialLinks, setSocialLinks] = useState([])
    const [bloomLinks, setBloomLinks] = useState([])
    const [bloomFeatureImages, setBloomFeatureImages] = useState([])
    const [profiles, setProfiles] = useState([])
    //const [bookmark, setBookmark] = useState({})
    const [defaultProfileId, setDefaultProfileId] = useState('')
	
    const fetchData = async () => {
		//if(user == null || user.uid != null){
		setLoading(true)
		const bloomRes = await findBloomByBloomName(paramBloomName)
		const socialLinksRes = await findAllSocialLinksByBloomName(paramBloomName)
		const bloomLinksRes = await findAllBloomLinksByBloomName(paramBloomName)
		const BloomFeatureImagesRes = await findAllBloomFeatureImagesByBloomName(paramBloomName)
		const profilesRes = await findAllLinkedProfilesByCompanyId(bloomRes.companyId, bloomRes.profileIdList)
		//console.log(profilesRes)

		/*if(user != null){
			const bookmarkRes = await findBookmarkByBloomName(user.uid, paramBloomName)
			if(bookmarkRes != null){
				setBookmark(bookmarkRes)
			}
		}*/
		await addBloomViewCountByBloomName(paramBloomName)

		setBloom(bloomRes)
		setBloomName(paramBloomName)
		setBloomBio(bloomRes.bio)
		setSocialLinks([...socialLinksRes])
		setBloomLinks([...bloomLinksRes])
		setBloomFeatureImages([...BloomFeatureImagesRes])
		setProfiles([...profilesRes])
		
		setDefaultProfileId()
		setLoading(false)
		//}
    }

    useEffect(() => {
		fetchData()
    }, []/*, [user]*/)

	const ProfileDropDown = () => {
		return (
			  <Dropdown onSelect={onSelect}>
				<Dropdown.Toggle variant="success" id="dropdown-profile">
				  {profiles.length > 0 ?　profiles[0].displayName : ''}
				</Dropdown.Toggle>
	
				<Dropdown.Menu>
				  {profiles.length > 0 && profiles.map(function(p, i){    
					  //if(p.id == profile.id){
					  //	return <Dropdown.Item eventKey={p.id} active>{p.displayName}</Dropdown.Item>;
					  //}else{
						  return <Dropdown.Item eventKey={i}>{p.displayName}</Dropdown.Item>;
					  //}
				  })}
				</Dropdown.Menu>
			  </Dropdown>
		)
	}

	const ProfileHeaderDropDownItems = () => {
		return (
			profiles.map(function(profile, i){  
				if(i==0){
					return <ShopProfilesHeader counter={i} profile={profile} bloomName={bloomName} isHidden={false}/>;
				}else{
					return <ShopProfilesHeader counter={i} profile={profile} bloomName={bloomName} isHidden={true}/>;
				}				
			})
		)
	}

	const ProfileDropDownItems = () => {
		return (
			profiles.map(function(profile, i){  
				if(i==0){
					return <ShopProfiles counter={i} profile={profile} bloomName={bloomName} socialLinks={socialLinks} isHidden={false}/>;
				}else{
					return <ShopProfiles counter={i} profile={profile} bloomName={bloomName} socialLinks={socialLinks} isHidden={true}/>;
				}				
			})
		)
	}

					
    return (
		<div>
			{ loading && 
				<p>loading...</p>
			}
			<img id="page-banner" src={bloom.bannerImage ? bloom.bannerImage : imgBannerMobile} alt="BloomTap Banner"/>
			<div className="bloom-content">	
				<div className="profile-content">	
					{<ProfileHeaderDropDownItems />}
					{profiles.length > 1 && <ProfileDropDown />}
					{<ProfileDropDownItems />}
				</div>
				
				<ul className="feature-images-container">
				{bloomFeatureImages.length > 0 && bloomFeatureImages.map(bloomFeatureImage => (
					<BloomFeatureImages bloomFeatureImage={bloomFeatureImage}/>
				))}
				</ul>
				<p className="bloom-bio">{bloomBio}</p>
				<ul className="bloom-links-list">
					{bloomLinks.length > 0 && bloomLinks.map(bloomLink => (
						<BloomLinks bloomName={bloomName} bloomLink={bloomLink}/>
					))}
				</ul>
			
			</div>
		</div>
    )
}

export default Home
