import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const BloomFeatureImages = (props) => {
	const { bloomFeatureImage } = props
	
  return (
	<li>
	  <Zoom>
		<img
		  alt="BloomTap Features"
		  src={bloomFeatureImage.bloomFeatureImage}
		/>
	  </Zoom>
	</li>
  );
};
export default BloomFeatureImages;