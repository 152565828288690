import React, { useEffect, useState } from 'react';
import { shareOnMobile } from "react-mobile-share";
import icLocation from '../images/icons/ic_location.svg'
import icPhone from '../images/icons/ic_phone.svg'
import icWhatsapp from '../images/icons/ic_whatsapp.svg'
import icMail from '../images/icons/ic_mail.svg'
import icShare from '../images/icons/ic_share.svg'
import icFb from '../images/icons/ic_fb.svg'
import icIg from '../images/icons/ic_ig.svg'
import icYt from '../images/icons/ic_yt.svg'
import icX from '../images/icons/ic_x.svg'
import icTikTok from '../images/icons/ic_tiktok.svg'

/*const handleBookmark = (user, bookmark, bloomName, e) => {
    e.preventDefault();
	if(user != null){
		if(bookmark.id != null){
			deleteBookmarkById(bookmark.id)
		}else{
			putBookmarkByBloomName(user.uid, bloomName)
		}
		setTimeout(function() {
		  window.location.reload(false);
		}, 1000);
	}else{
		window.location.href = "/login?action=bookmark&bloomname=" +bloomName;
	}
};*/

async function getBase64ImageFromUrl(imageUrl) {
	var res = await fetch(imageUrl, {mode: 'no-cors'});
	var blob = await res.blob();
  
	return new Promise((resolve, reject) => {
	  var reader  = new FileReader();
	  reader.addEventListener("load", function () {
		  resolve(reader.result);
	  }, false);
  
	  reader.onerror = () => {
		return reject(this);
	  };
	  reader.readAsDataURL(blob);
	})
}


function ShopProfiles(props) {
    const { counter, profile, bloomName, socialLinks, isHidden } = props
    var goolgeMapUrl = profile.placeId != null ? "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" + profile.placeId : "#"
	
	
	const [imageBase64, setImageBase64] = useState('')

	const fetchData = async () => {
		getBase64ImageFromUrl(profile.photoUrl)
		.then(result=>{
			setImageBase64(result);
		})
		.catch(err => console.error(err))
	}
  
	useEffect(() => {
		fetchData()
	}, [])
  
    return (
		<div id={"profile" + counter} className={"profile-container " + (isHidden ? 'visually-hidden' : '')}>
			<div className="profile-address"><img src={icLocation}/>  <a href={goolgeMapUrl} target="_blank" className="btn-google-map">{profile.address}</a></div>
			<ul className="nav-tab-button">
				{
					socialLinks.length > 0 && socialLinks.map(socialLink => (
							socialLink.socialPlatform == 'Facebook' ? <li><a href={socialLink.socialLinkURL} target="_blank"><img src={icFb}/></a></li> : 
							socialLink.socialPlatform == 'Instagram' ? <li><a href={socialLink.socialLinkURL} target="_blank"><img src={icIg}/></a></li> : 
							socialLink.socialPlatform == 'Youtube' ? <li><a href={socialLink.socialLinkURL} target="_blank"><img src={icYt}/></a></li> : 
							socialLink.socialPlatform == 'X' ? <li><a href={socialLink.socialLinkURL} target="_blank"><img src={icX}/></a></li> : 
							socialLink.socialPlatform == 'TikTok' ? <li><a href={socialLink.socialLinkURL} target="_blank"><img src={icTikTok}/></a></li> : ""
					))
				}
				{
					profile.isWhatsapp == true ? (
						<li><a href={"https://wa.me/+" + profile.phoneCode + profile.phone+ "?text=Hi!"}><img src={icWhatsapp}/></a></li>
					) : (
						<li><a href={"mailto:" + profile.email}><img src={icMail}/></a></li>
					)
				}
				<li><a href={"tel:+" + profile.phoneCode + profile.phone}><img src={icPhone}/></a></li>
				<li><a href="#" onClick={() =>
					shareOnMobile({
						text: "Hey checkout my BloomTap",
						url: "https://bloomtap.io/" + bloomName,
						title: profile.displayName,
						image: imageBase64
					})
				} ><img src={icShare}/></a></li>
			</ul>
		</div>
    )
}

export default ShopProfiles
